/** @jsx jsx */
import { jsx } from "@emotion/core"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Badge from "../components/badge"
import Card from "../components/card"
import Layout from "../components/layout"

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        name
        type
        features
        technologies
      }
      body
    }
  }
`

export default function Project({ data: { mdx: project } }) {
  return (
    <Layout>
      <Card>
        <h1>{project.frontmatter.name}</h1>
        <p>{project.frontmatter.type}</p>
        <h4>Technologies</h4>
        <p>
          {project.frontmatter.technologies.map(technology => (
            <Badge key={technology}>{technology}</Badge>
          ))}
        </p>
        <h4>Features</h4>
        <p>
          {project.frontmatter.features.map(feature => (
            <Badge key={feature}>{feature}</Badge>
          ))}
        </p>
        <MDXRenderer>{project.body}</MDXRenderer>
      </Card>
    </Layout>
  )
}
